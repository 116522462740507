@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Montserrat";
}

.head {
  font-family: 'Space Grotesk', sans-serif;
}

::-webkit-scrollbar {
    width: 0.5em;
    /* Set the width of the scrollbar (adjust as needed) */
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Set the color of the scrollbar thumb */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.animate  {
  top: 0;
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%
  }
}